<template>
  <div class="activity-container p-6 bg-white my-4 flex items-center">
    <div
      class="flex"
      style="flex: 1; width: 100%; padding-right: 15px; box-sizing: border-box"
    >
      <div class="sort font-bold mr-6">{{ index + 1 }}</div>
      <div class="activity-info">
        <p class="activity-name text-base font-bold mb-4">
          {{ info.type }}
        </p>
        <div class="activity-time flex text-sm font-bold mb-2">
          <p>
            <span>Start Time：</span
            >{{
              info.start_time && info.start_time != ""
                ? $dayjs(info.start_time * 1000).format("YYYY/MM")
                : ""
            }}
          </p>
          <p>
            <span>End Time：</span
            >{{
              info.end_time && info.end_time != ""
                ? $dayjs(info.end_time * 1000).format("YYYY/MM")
                : ""
            }}
          </p>
          <p style="color: #579eff">
            {{ info.hours_peer_week }}
            {{ info.hours_peer_week > 1 ? "hours" : "hour" }} per week
          </p>
        </div>
        <div class="activity-location flex text-sm font-bold mb-4">
          <p><span>Position/Role：</span>{{ info.position }}</p>
          <p><span>Organization：</span>{{ info.org }}</p>
        </div>
        <div class="activity-desc">
          <p class="text-sm font-bold">
            {{ info.desc }}
          </p>
        </div>
      </div>
    </div>
    <div
      style="width: 120px; text-align: center"
      v-if="hideOps === false || showEdit || showRemove"
    >
      <div class="flex flex-col items-center gap-2" v-if="hideOps === false">
        <div class="status-lab">
          {{ info.status }}
        </div>

        <div>
          <div class="mr-2 w-20 edit-btn custom-btn" @click="handleEdit">
            Edit
          </div>
        </div>
        <div>
          <div class="mr-2 w-20 delete-btn custom-btn" @click="handleDelete">
            Delete
          </div>
        </div>
      </div>
      <div
        class="flex flex-col items-center gap-2"
        v-if="showEdit || showRemove"
      >
        <div class="status-lab">
          {{ info.status }}
        </div>

        <div v-if="showEdit">
          <el-button
            type="primary"
            class="mr-2 w-20 edit-btn"
            style="width: 100px"
            @click="handleEdit"
            >Edit</el-button
          >
        </div>
        <div v-if="showRemove">
          <div
            class="custom-btn remove-btn"
            style="background-color: #ff8e3b"
            @click="handleRemove"
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activity",
  props: {
    info: Object,
    index: Number,
    hideOps: Boolean,
    showRemove: Boolean,
    showEdit: Boolean,
  },
  methods: {
    handleEdit() {
      this.$emit("edit");
    },
    handleRemove() {
      this.$emit("remove");
    },
    handleDelete() {
      this.$confirm(
        "Are you sure you want to delete this activity?",
        "Delete Activity",
        {
          type: "warning",
          confirmButtonText: "Confirm",
        }
      )
        .then(() => {
          this.$emit("delete");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  width: 100%;
  border-left: 3px solid var(--c-primary);
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }
  .activity-info {
    .activity-name {
      color: #333333;
    }
    .activity-time {
      color: var(--c-primary);
      span {
        color: #999999;
      }
      p {
        margin-right: 45px;
      }
    }
    .activity-location {
      color: #ff8900;
      span {
        color: #999999;
      }
      p {
        margin-right: 60px;
      }
    }
    .activity-desc {
      color: #666666;
    }
  }
  .delete-btn {
    background: #ff8474;
    border-color: #ff8474;
  }
  .custom-btn {
    border-radius: 5px;
    padding: 3px 10px;
    color: white;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
  }
  .edit-btn {
    margin-top: 20px;
    background: #579eff;
  }
  .remove-btn {
    width: 100px;
  }

  .status-lab {
    margin-right: 20px;
    color: #333333;
  }
}
</style>
