<template>
  <div class="p-4 pb-0">
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center">
        <i
          class="el-icon-arrow-left font-bold text-xl cursor-pointer"
          style="color: #999999"
          @click="goBack"
        />
        <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
          {{ entryInfo.title }}
        </p>
      </div>
    </div>
    <div class="flex bg-white category-content">
      <div class="w-3/5 px-12 py-8 overflow-y-scroll category-info">
        <p class="font-bold text-lg mb-2">Category</p>
        <div class="content mb-4">{{ categoryName }}</div>
        <p class="font-bold text-lg mb-2">Introduction</p>
        <div class="content mb-4">{{ entryInfo.intro || "-" }}</div>
        <p class="font-bold text-lg mb-2" v-if="activities.length > 0">
          Activities
        </p>
        <div
          class="shadow-xl"
          v-for="(activity, index) in activities"
          :key="index"
        >
          <Activity :info="activity" :index="index" :hideOps="true" />
        </div>
        <div v-if="summaryList.length > 0">
          <p class="font-bold text-lg mb-2">Summaries</p>
          <div v-for="(item, index) in summaryList" :key="index">
            <p v-html="item.name"></p>
            <p v-if="item.intro" style="margin-top: 15px; font-size: 14px">
              {{ item.intro }}
            </p>
            <el-input
              disabled
              style="margin: 15px 0"
              v-model="item.value"
              type="textarea"
              :rows="5"
            ></el-input>
          </div>
        </div>
        <div v-if="summaryList.length > 0">
          <p class="font-bold text-lg mb-2">Supplementary Material</p>
          <div v-for="(item, index) in materialList" :key="index">
            <p v-html="item.name"></p>
            <p style="margin-top: 15px; font-size: 14px">
              {{ item.intro }}
            </p>
            <el-button
              style="
                width: 60px;
                height: 32px;
                margin-right: 20px;
                margin-top: 12px;
              "
              size="small"
              type="primary"
              @click="openFile(item)"
              v-if="item.value"
            >
              View
            </el-button>
            <p class="file-tips" v-else>No attachments were uploaded.</p>
          </div>
        </div>
      </div>
      <div class="flex-1 px-12 py-8 overflow-y-scroll">
        <div class="mb-4">
          <p class="font-bold text-lg" style="color: #ff8e3b">Entry Info</p>
          <p class="mt-3">Category: {{ categoryName }}</p>
          <p class="mt-1">
            Entry Type: {{ formatEntryType(entryInfo.entry_type) }}
          </p>
          <!-- <p class="mt-1">
            Award Title:
            {{ entryInfo.sub_category ? entryInfo.sub_category.name : "-" }}
          </p> -->
          <p class="mt-1">
            Student(s):
            {{
              members
                .map(
                  (i) =>
                    `${i.student.student_givenName_pinyin} ${i.student.student_lastName_pinyin}`
                )
                .join("，")
            }}
          </p>
        </div>
        <div class="mb-4">
          <p class="font-bold text-lg" style="color: #ff8e3b">
            Recommend This Entry
          </p>
          <div>
            <el-form>
              <p class="my-3">
                Teacher recommendations serve as additional merit in the
                evaluation process and have the potential to secure additional
                points for the entry.
              </p>
              <p class="my-3" v-if="contactRecommendInfo" style="color: #C0C4CC;font-size: 16px;background-color: #F5F7FA;padding: 4px 8px;box-sizing: border-box;border-radius: 3px;border-color: #E4E7ED;border-style: solid;border-width: 1px;">
                  {{ contactRecommendInfo.recommend_info  }}
              </p>
              <el-form-item>
                <el-input
                  v-if="!contactRecommendInfo"
                  placeholder="Provide a recommendation to share your endorsement. (Up to 150 words)"
                  type="textarea"
                  rows="5"
                  v-model="recommendInfo"
                  maxlength="150"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
            <el-button type="primary"   v-if="!contactRecommendInfo" @click="onConfirm">Submit</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStudentInfo,
  getReviewEntry,
  getTypes,
  getReviewEntryActivities,
  getCategoryInfoAndMaterials,
  getEntryPeoplesByReviewEntryId,
  getSingaporeQiniuToken,
  addContactRecommend,
  getSubCategoryList,
  getContactRecommendInfo
} from "../../api/eae";
import {
  getUserId,
  ENTRY_TYPES,
} from "../../utils/store.js";
import Activity from "../../components/Activity.vue";
import { find } from "lodash-es";
import * as qiniu from "qiniu-js";
export default {
  components: {
    Activity,
  },
  data() {
    return {
      ENTRY_TYPES,
      entryInfo: {
        id: "",
        category_id: "",
        title: "",
        intro: "",
        open_status: "YES",
        activity_option_id: "",
        entry_type: "",
        project_id: "",
        user_id: "",
      },
      categoryName: "",
      custom_ddl_time: "",
      activities: [],
      fileList: [],
      types: [],
      activities: [],
      shareLink: "",
      form: {
        email: "",
      },
      studentList: [],
      materialList: [],
      summaryList: [],
      members: [],
      attachmentUrl: "",
      recommendInfo: "",
      awardList: [],
      recommendForm: {
        recommendInfo: "",
      },
      contactRecommendInfo:{}
    };
  },
  watch: {
    "entryInfo.category_id": {
      handler(newVal, oldVal) {
        this.categoryName = find(this.types, (i) => i._id.$id === newVal)
          ? find(this.types, (i) => i._id.$id === newVal).name
          : "-";
      },
    },
  },
  mounted() {
    this.fetchData();
    this.requestContactRecommendInfo()
  },
  methods: {

    formatEntryType(entry_type) {
      if (entry_type == "team_story" || entry_type == "indie_story") {
        return 'Storyline'

      } else if (entry_type == "team_exp" || entry_type == "indie_exp") {
        return 'Single Activity'
      }
      return ENTRY_TYPES[entry_type];
    },


    requestContactRecommendInfo(){
      getContactRecommendInfo(getUserId(),this.$route.query.studentId,this.$route.query.id).then((res)=>{
          this.contactRecommendInfo = res.data.data
      })
    },

    goBack() {
      this.$router.push({
        path: "/home/advisorStudentList",
      });
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getSingaporeQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.attachmentUrl =
              "https://ap.learningfirst.tech/" + res.key;
          },
        };
        this.observable.subscribe(observer);
      } else {
      }
    },
     onConfirm() {
      if (!this.recommendInfo) {
        this.$message.warning("Please input recommend information");
        return
      }
      this.$confirm('Only one recommendation allowed per entry. Once submitted, it cannot be modified. Confirm your current recommendation?','Recommend Confirmation',{
        confirmButtonText:'Submit',
        cancelButtonText:'Cancel',
        type:'warning'
      }).then((res)=>{
          addContactRecommend({
            contact_id: getUserId(),
            student_id: this.$route.query.studentId,
            entry_id: this.$route.query.id,
            recommend_info: this.recommendInfo,
            recommend_url: this.attachmentUrl,
          }).then((res)=>{
                if (res.data.code === 0) {
                  this.attachmentUrl = "";
                  this.recommendInfo = "";
                  this.$message.success("Recommend successful");
                  this.requestContactRecommendInfo()
                }else {
                  this.$message.success(res.data.msg);
                }
          })
          
      }) .catch(() => {});
    },
    openFile(item) {
      window.open(item.value, "_blank");
    },
    requestCurrentStudentInfo(){
      getStudentInfo(this.$route.query.studentId).then((res)=>{
        let item = {
          student: res.data.data
        }
        this.members.push(item)
      })
    },

    async fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const id = this.$route.query.id;
        const result = await getTypes();
        const res = await getReviewEntryActivities(id);
        this.activities = res.data.data;
        this.types = [
          ...result.data.data.filter((item) => item.name !== "Others"),
          ...result.data.data.filter((item) => item.name === "Others"),
        ];
        const memberRes = await getEntryPeoplesByReviewEntryId(id);

        this.members = memberRes.data.data;


        getReviewEntry(id).then((res) => {
          this.entryInfo = res.data.data;
          this.entryInfo.id = id;
          if(this.entryInfo.application_type == '个人'){
            this.requestCurrentStudentInfo()
          }
          this.fileList = res.data.data.attachment_url
            ? [{ name: "file", url: res.data.data.attachment_url }]
            : [];
          getCategoryInfoAndMaterials(
            this.$route.query.studentId,
            res.data.data.category_id
          ).then((res) => {
            let result = res.data.data;
            let sources = result.category.sourceList;
            let temp_sourceList = [];
            if (sources && sources.length > 0) {
              for (let i = 0; i < sources.length; i++) {
                let source = sources[i];
                if (
                  source.suit &&
                  source.suit.indexOf(this.entryInfo.entry_type) >= 0
                ) {
                  temp_sourceList.push(source);
                }
              }
            }
            if (result.material) {
              for (let i = 0; i < temp_sourceList.length; i++) {
                temp_sourceList[i].value = result.material.materials[i];
              }
            }
            for (let i = 0; i < temp_sourceList.length; i++) {
              if (temp_sourceList[i].type == "file") {
                this.materialList.push(temp_sourceList[i]);
              } else {
                this.summaryList.push(temp_sourceList[i]);
              }
            }
          });
          getSubCategoryList(res.data.data.category_id).then(
            (res) => (this.awardList = res.data.data)
          );
        });
      } catch (error) {
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-content {
  height: calc(100vh - 160px);
  .content {
    white-space: pre-line;
    color: rgb(102, 102, 102);
    word-break: keep-all;
  }
  .category-info {
    border-right: 1px solid #dcdfe6;
  }
}
.student-item {
  .student-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .view-btn {
    padding: 6px 26px;
    border-radius: 5px;
    cursor: pointer;
    background: #579eff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
.share-copy-link {
  margin-left: 12px;
}
.tips {
  color: #c0c4cc;
}
.file-tips {
  margin-top: 12px;
  color: #ff8e3b;
}
</style>

<style lang="scss">
.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "PT Sans", "SF UI Display", ".PingFang SC", "PingFang SC",
      "Neue Haas Grotesk Text Pro", "Arial Nova", "Segoe UI", "Microsoft YaHei",
      "Microsoft JhengHei", "Helvetica Neue", "Source Han Sans SC",
      "Noto Sans CJK SC", "Source Han Sans CN", "Noto Sans SC",
      "Source Han Sans TC", "Noto Sans CJK TC", "Hiragino Sans GB", sans-serif;
    text-rendering: optimizelegibility;
    margin-bottom: 1em;
    font-weight: bold;
    line-height: 1.8rem;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
</style>
